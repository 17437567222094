import axios from "axios"
import { kycAxios } from "../../Config/axios"

export const getIdentityVerify = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await kycAxios().post( "/applicants", data )
            .then(
                response => resolve( response.data ),
                error => reject(error)
            )
            .catch(
                error => console.log(error)
            )
    })
}

export const uploadFiles = data => async () => {
    return await kycAxios().post( "/files", data)
}


export const getDocumentsVerify = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await kycAxios().post( "/documents", data )
            .then(
                response => resolve( response ),
                error => reject( error )
            )
            .catch( error => console.log( error ) )
    })
}

export const updateKycDetails = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await kycAxios().post( "/auth/updateProfile", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch( error => console.log( error ) )
    })
}


export const getVerifications = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await kycAxios().post( "/verifications", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch( error => console.log( error ) )
    } )
}

export const getUserVerification = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await kycAxios().get( `/verifications/${data}` )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch( error => console.log( error ) )
    } )
}