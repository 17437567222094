let initialState = {
    stateData: [],
    cityData: [],
    cuntryData: []
}

export default function ( state = initialState, action ) {
    switch ( action.type ) {
        case 'STATE_DATA':
            return { ...state, stateData: action.payload };
        case 'CITY_DATA':
            return { ...state, cityData: action.payload };
        case 'CUNTRY_DATA':
            return { ...state, cuntryData: action.payload };
        default:
            return state
    }
}