import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { getDocumentsVerify, uploadFiles } from '../../../Redux/action/Kyc';
import IsLoadingHOC from '../../IsLoadingHOC'

const DocumentSubmit = (props) => {
    const { setLoading, setActiveStep } = props
    const dispatch = useDispatch();
    const { tempData } = useSelector( state => state.auth )
    const [disabled, setDisabled] = useState( false )
    const [values, setValues] = useState( {
        type: "PASSPORT",
        document_number: "",
        issue_date: "",
        expiry_date: "",
        front_side_id: "",
        back_side_id: "",
        applicant_id: tempData.applicant_id
    } )
    
    const [docFront, setDocFront] = useState( "" )
    const [docBack, setDocBack] = useState( "" )
    
    const trimFileName = (string, l = 5) => {
        if ( string.length > 25 ) {
            return (
                String(string).slice(0, 5) +
                String(string).slice(2, 2 + l) +
                "..." +
                String(string).slice(string.length - l, string.length)
            )
        } else {
            return string
        }
    }



    const submitHanlder = async () => {
        const { document_number, issue_date, expiry_date, front_side_id, back_side_id, applicant_id } = values
        if ( !applicant_id ) {
            setActiveStep( 0 )
            toast.error( "Something went wrong!" )
            return
        } else if ( !document_number ) {
            toast.error( "Please Enter Passpost Number" )
            return
        } else if ( !front_side_id ) {
            toast.error( "Please Upload Front Side Photo of your Passpost" )
            return
        } else if ( !back_side_id ) {
            toast.error( "Please Upload Back Side Photo of your Passpost" )
            return
        } else if ( !issue_date ) {
            toast.error( "Please Enter Issued Date" )
            return
        } else if ( !expiry_date ) {
            toast.error( "Please Enter Expiry Date" )
            return
        } else {
            setLoading( true )
            await dispatch( getDocumentsVerify( values ) )
                .then(
                    response => {
                        setActiveStep( 2 )
                        setLoading( false )
                    },
                    error => {
                        console.log( error );
                        setLoading( false )
                        if(error.response.data.errors){
                            toast.error( error.response.data.errors[0].message )
                        }
                    }
                )
                .catch(
                    error => console.log( error )
                )
        }
    }
    
    return (
        <div className="kyc-process-inner-card py-md-5 px-lg-5 p-4 mx-md-3 mx-0 has-box-shadow text-center">
            <div className="row no-gutters text-center mt-5">
                <div className="col-12">
                    <h2 className="font-weight-bold">Submit your Passport</h2>
                </div>
            </div>
            <div className="row mt-2 mb-4">
                <div className="col-12 m-3">
                    <div className="form-group" style={{width:"100%"}}>
                        <label style={{display:"block", textAlign:"left"}} htmlFor="passport-document-number">Passport Number</label>
                        <input
                            type="text"
                            className="form-control secondary-input"
                            id="passport-document-number"
                            value={values.document_number}
                            onChange={e => setValues( { ...values, document_number: e.target.value } )}
                            placeholder="Passport Number" />
                    </div>
                </div>
                <div className="col-12 m-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-start">
                            <label className="dashed-border p-4" htmlFor="docFront">
                                <div className="image-upload">
                                    <img className="mx-auto cursor" src={require("../../../assets/images/Upload.svg").default} style={{width:"30px"}} />
                                    <input
                                        type="file"
                                        className="custom-file-input d-none"
                                        accept="image/*"
                                        onChange={async e => {
                                            setLoading( true )
                                            setDocFront( e.target.files[0] )
                                            let formData = new FormData()
                                            formData.append("file", e.target.files[0])
                                            await dispatch( uploadFiles( formData ) )
                                                .then(
                                                    response => {
                                                        setLoading( false )
                                                        setDisabled( false )
                                                        setValues( { ...values, front_side_id: response.data.file_id } )
                                                    },
                                                    error => {
                                                        setLoading( false )
                                                        console.log( "responseError", error.response.data )
                                                        setDisabled( true )
                                                        if(error.response.data.errors){
                                                            toast.error( error.response.data.errors[0].message )
                                                        }
                                                    }
                                                )
                                                .catch()
                                        }}
                                        id="docFront" />
                                    <p className="mt-3">Upload Front Side</p>
                                    <small>{!docFront
                                        ? "We accept BMP, GIF, HEIC, JPEG, PNG and WEBP, up to 50 MB"
                                        : trimFileName( docFront.name )}</small>
                                </div>
                            </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 d-flex mt-md-0 mt-3">
                            <label className="dashed-border p-4" htmlFor="docBack">
                                <div className="image-upload">
                                    <img className="mx-auto cursor" src={require("../../../assets/images/Upload.svg").default} style={{width:"30px"}} />
                                    <input
                                        type="file"
                                        className="custom-file-input d-none"
                                        accept="image/*"
                                        onChange={async e => {
                                            setLoading( true )
                                            setDocBack( e.target.files[0] )
                                            let formData = new FormData()
                                            formData.append("file", e.target.files[0])
                                            await dispatch( uploadFiles( formData ) )
                                                .then(
                                                    response => {
                                                        setLoading( false )
                                                        setDisabled( false )
                                                        setValues( { ...values, back_side_id: response.data.file_id } )
                                                    },
                                                    error => {
                                                        setLoading( false )
                                                        console.log( "responseError", error )
                                                        setDisabled( true )
                                                        if(error.response.data.errors){
                                                            toast.error( error.response.data.errors[0].message )
                                                        }
                                                    }
                                                )
                                                .catch()
                                        }}
                                        id="docBack" />
                                    <p className="mt-3">Upload Back Side</p>
                                    <small>{!docBack
                                        ? "We accept BMP, GIF, HEIC, JPEG, PNG and WEBP, up to 50 MB"
                                        : trimFileName( docBack.name )}</small>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group" style={{width:"100%"}}>
                                <label style={{display:"block", textAlign:"left"}} htmlFor="passport-issued-date">Issue Date</label>
                                <input
                                    type="date"
                                    className="form-control secondary-input"
                                    id="passport-issued-date"
                                    value={values.issue_date}
                                    onChange={e => setValues( { ...values, issue_date: e.target.value } )}
                                    placeholder="Date" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group" style={{width:"100%"}}>
                                <label style={{display:"block", textAlign:"left"}} htmlFor="passport-expiry-date">Expiry Date</label>
                                <input
                                    type="date"
                                    className="form-control secondary-input"
                                    id="passport-expiry-date"
                                    value={values.expiry_date}
                                    onChange={e => setValues( { ...values, expiry_date: e.target.value } )}
                                    placeholder="Date" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 offset-lg-9 col-md-6 offset-md-6 col-12">
                    <button
                        className="btn btn-gradient-secondary w-100"
                        disabled={disabled}
                        onClick={submitHanlder}>Next</button>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC( DocumentSubmit )
