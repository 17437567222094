import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getIdentityVerify } from '../../../Redux/action/Kyc';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../../IsLoadingHOC'

const IdentityVerify = (props) => {

    const { setActiveStep, setLoading } = props
    const { app, auth } = useSelector( state => state )
    const dispatch = useDispatch();

    const [values, setValues] = useState( {
        first_name: "",
        last_name: "",
        dob: "",
        email: auth.user.email,
        residence_country: "",
        pep: false,
        gender: "",
        type: "PERSON"
    } )


    useEffect(() => {
        
    }, [] )
    

    const submitHandler = async() => {
        const { first_name, last_name, dob, email, residence_country, gender } = values;
        if ( !first_name ) {
            toast.error( "Please Enter First Name" )
            return
        } else if ( !last_name ) {
            toast.error( "Please Enter Last Name" )
            return
        } else if ( !dob ) {
            toast.error( "Please Enter DOB" )
            return
        } else if ( !email ) { // dispatch( { type: "LOGOUT" } ), // Because, Email ID Will come from Local Storage
            return
        } else if ( !residence_country ) {
            toast.error("please Select Cunntry")
        } else if ( !gender ) {
            toast.error("Please Select your Gender")
        } else {
            setLoading( true )
            await dispatch( getIdentityVerify( values ) )
                .then(
                    response => {
                        setLoading( false )
                        dispatch( { type: "SAVE_USER_TEMP_DATA", payload: response } )
                        setActiveStep( 1 )
                    },
                    error => {
                        setLoading( false )
                        error.response.data.errors.map( ( error ) => (
                            toast.error(error.message)
                        ))
                    }
                )
        }
        
    }
    



    return (
        <div className="kyc-process-inner-card py-md-5 px-lg-5 p-4 mx-md-3 mx-0 has-box-shadow text-left">
            <div className="row no-gutters text-center p-3">
                <div className="col-12">
                    <h2 className="font-weight-bold">Verify your identity</h2>
                </div>
            </div>
            <div className="row my-5">
                <div className="col-lg-12 col-12">
                    <label className="mr-3 mb-lg-0 mb-3">Please select Gender -
                    </label>
                    <div className="form-check form-check-inline mr-3">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="male"
                            onChange={() => setValues( { ...values, gender: "M" } )}
                            value="male" />
                        <label className="form-check-label" htmlFor="male">Male</label>
                    </div>
                    <div className="form-check form-check-inline mr-3">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="female"
                            onChange={() => setValues( { ...values, gender: "F" } )}
                            value="female" />
                        <label className="form-check-label" htmlFor="female">Female</label>
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-lg-6 col-12">
                    <div className="form-group">
                        <label htmlFor="first-name">First Name</label>
                        <input
                            type="text"
                            className="form-control secondary-input"
                            id="first-name"
                            value={values.first_name}
                            onChange={e => setValues( { ...values, first_name: e.target.value } )}
                            placeholder="Enter First Name" />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="form-group">
                        <label htmlFor="last-name">Last Name</label>
                        <input
                            type="text"
                            className="form-control secondary-input"
                            id="last-name"
                            value={values.last_name}
                            onChange={e => setValues( { ...values, last_name: e.target.value } )}
                            placeholder="Enter Last Name" />
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-lg-6 col-12">
                    <div className="form-group">
                        <label htmlFor="passport-issued-date">Date</label>
                        <input
                            type="date"
                            onChange={e => setValues( {...values, dob: e.target.value} )}
                            className="form-control secondary-input"
                            id="passport-issued-date"
                            placeholder="Date" />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="form-group">
                        <label htmlFor="nationality">Nationality</label>
                        <Select
                            options={app.cuntryData}
                            onChange={e => setValues( { ...values, residence_country: e.value } )}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-12 my-2">
                    <div className="form-check">
                        <input
                            className="form-check-input" type="checkbox"
                            checked={values.pep}
                            onChange={e => setValues( { ...values, pep: e.target.checked } )}
                            id="termsAndConditions" />
                        <label className="form-check-label font-weight-bold text-dark" htmlFor="termsAndConditions">
                            Politically exposed person (PEP)
                        </label>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-6 offset-lg-3 col-12">
                    <button className="btn btn-gradient-secondary w-100" onClick={submitHandler}>Start
                        Verification</button>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(IdentityVerify)
