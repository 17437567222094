let initialState = {
    user: "",
    token: "",
    tempData: ""
}

export default function ( state = initialState, action ) {
    switch ( action.type ) {
        case "USER_DATA_UPDATE":
            return { ...state, user: action.payload }
        case "SAVE_TOKEN":
            return { ...state, token: action.payload }
        case "LOGOUT":
            return { ...state, token: "", user: "" }
        case "SAVE_USER_TEMP_DATA":
            return { ...state, tempData: { ...state.tempData, ...action.payload } }
        default:
            return state
    }
}