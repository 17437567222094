import { Formik } from 'formik';
import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { varifyOtp, sendOTP } from '../../Redux/action/Auth';

const Step1 = ( props ) => {
    const { setActiveStep } = props
    const [userOtp, setUserOtp] = useState( "" )
    const handleChangeOtp = otp => setUserOtp( otp );

    const dispatch = useDispatch()
    const { tempData } = useSelector( state => state.auth )
    const handleSubmit = async () => {
        if ( userOtp.length == 6 ) {
            await dispatch( varifyOtp( {
                mobile: tempData.mobile,
                country_code: tempData.country_code,
                otp: userOtp
            } ) )
                .then(
                    response => {
                        toast.success( response.message )
                        dispatch( { type: "SAVE_TOKEN", payload: response.data.token } )
                        setActiveStep( 2 )
                    },
                    error => {
                        toast.error( error.response.data.message )
                    }
                )
                .catch(
                    error => console.log( "error", error )
                )
        }
        else {
            toast.error( "Please Enter OTP" )
        }
    }


    const handleResendOtp = async () => {
        await dispatch( sendOTP( {
            mobile: tempData.mobile,
            country_code: tempData.country_code
        } ) ).then(
            response => {
                toast.success( response.message )
            },
            error => {
                toast.error( error.response.data.message )
            }
        ).catch(
            error => console.log( "error", error )
        )
    }


    


    return (
        <div className="py-md-7 p-5 mx-3 has-box-shadow">
            <div className="form-heading my-5">
                <h3 className="font-weight-bold text-dark">
                    Confirm your mobile number
                </h3>
                <h3 className="font-weight-bold text-dark">
                    for seam less experience
                </h3>
            </div>
            <h4>OTP is sent to:</h4>
            <h2 className="font-weight-bold text-dark">
                {tempData.mobile}{' '}
                <span>
                    <img
                        alt=""
                        className="edit-number"
                        src="/images/icon-edit-number.svg"
                    />
                </span>
            </h2>
            <form className="my-4">
                <div className="row">
                    <div className="col-lg-9 col-12">
                        <div className="form-group">
                            <label htmlFor="otp">Enter OTP: </label>
                            <div id="otp-inner-wrapper">
                                <OtpInput
                                    value={userOtp}
                                    onChange={handleChangeOtp}
                                    numInputs={6}
                                />
                            </div>
                        </div>
                        <small className="form-text text-muted text-left mt-4">
                            Didn't receive OTP?{' '}
                            <span
                                onClick={handleResendOtp}
                                style={{ cursor: 'pointer' }}
                                className="font-weight-bold text-dark">
                                Resend OTP
                            </span>
                        </small>
                    </div>
                </div>
            </form>
            <button
                id="send-otp-button"
                className="btn btn-gradient-secondary w-100 my-5"
                onClick={handleSubmit}
            >
                Sign Up
            </button>

        </div>
    )
}

export default Step1
