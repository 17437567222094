import axios from "axios";
import { withoutAuthAxios } from "../../Config/axios";


export const getStateData = data => async dispatch => {
    await withoutAuthAxios().post(
        '/country/wise-state/',
        data,
    ).then(
        async ( response ) => {
            console.log( "response", response );
            let stateData = [];
            stateData = await response.data.data.map(
                ( { id: value, name: label, ...rest } ) => ( { value, label, ...rest } ),
            );
            dispatch( { type: 'STATE_DATA', payload: stateData } );
        },
        error => {
            console.log( error )
        }
    ).catch( error => console.log( error ) )
};

export const getCityData = data => async dispatch => {
    await withoutAuthAxios().post(
        '/state/wise-city/',
        data,
    ).then(
        async ( response ) => {
            let cityData = [];
            cityData = await response.data.data.map(
                ( { id: value, name: label, ...rest } ) => ( { value, label, ...rest } ),
            );
            dispatch( { type: 'CITY_DATA', payload: cityData } );
        },
        error => {
            console.log( error )
        }
    ).catch( error => console.log( error ) )
};


export const getTotenizedProperty = data => async ()=> {
    return new Promise( async ( resolve, reject ) => {
        await axios.post( "https://token-api.aqarchain.io/admin/tokenized-property/search", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    })
}

export const getAllCuntry = () => async dispatch => {
    await axios.get( "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json" )
        .then(
            async ( response ) => {
                let cuntryData = [];
                cuntryData = await response.data.map(
                    ( { code: value, name: label, ...rest } ) => ( { value, label, ...rest } ),
                );
                dispatch( { type: "CUNTRY_DATA", payload: cuntryData } )
            },
            error => {
                console.log( error );
            }
        )
        .catch(
            error => console.log( error )
        )
}