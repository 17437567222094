import { Route, Switch } from 'react-router-dom';
// import Authorization from './Authorization';
import { useSelector } from 'react-redux';
import landing from "../components/landing/landing";
import discover from "../components/discover/discover";
import discover2 from "../components/discover/discover2";
import discover3 from "../components/discover/discover3";
import { injected } from '../connectors'

import AppRoute from './AppRoute';
import PublicLayout from '../layout/Public';
import details from "../components/details/details";
import Login from '../components/login/login';
import Dashboard from '../components/Dashboard/Dashboard';
import PrivateLayout from '../layout/Private';
import PrivateRoute from './PrivateRoute';
import Profile from '../components/Dashboard/Profile/Profile';
import KYC from '../components/Dashboard/Kyc/Kyc';
import Registration from '../components/Registration';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';
import { useEffect } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { setupNetwork } from '../utils/wallet';
import KycProcess from '../components/Dashboard/KycProcess';
import Portfolio from '../components/Dashboard/Portfolio/index'
import emailVerify from '../components/EmailVerify/index'
import ResendLink from '../components/EmailVerify/resendLink';

// const ALLUser = Authorization(['TEACHER', 'STUDENT', 'SCHOOL_ADMINISTRATOR']);
// const TeacherAndSchoolAdmin = Authorization( ["SCHOOL_ADMINISTRATOR", "TEACHER"] )
// const Teacher = Authorization( ['TEACHER'] )
// const SchoolAdmin = Authorization( ["SCHOOL_ADMINISTRATOR"] )



const Routing = () => {


  const { activate } = useWeb3React()

  useEffect(() => {
    defaultConnect();

  }, []);

  useEffect(() => {
    console.warn = () => null
    if (window && window.ethereum) {
      window.ethereum.on('chainChanged', async () => {
        try {
          await activate(injected, async (error) => {
            if (error instanceof UnsupportedChainIdError) {
              const hasSetup = await setupNetwork()
              if (hasSetup) {
                activate(injected)
              }
            }
          })
        } catch (error) {
          console.error(error)
        }
      })
    }
  }, [])

  const defaultConnect = async () => {
    try {
      await activate(injected, async (error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork()
          if (hasSetup) {
            activate(injected)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }


  return (
    <Switch>
      <AppRoute
        exact
        path="/"
        component={landing}
        layout={PublicLayout} />

      <AppRoute
        exact
        path="/discover"
        component={discover2}
        layout={PublicLayout} />

      <AppRoute
        exact
        path="/discover2"
        component={discover2}
        layout={PublicLayout} />

      <AppRoute
        exact
        path="/registration/verify/email/:token"
        component={emailVerify}
        layout={PublicLayout} />

      <AppRoute
        exact
        path="/registration/resend/email"
        component={ResendLink}
        layout={PublicLayout} />

      <AppRoute
        exact
        path="/details/:id"
        component={details}
        layout={PublicLayout} />

      <AppRoute
        exact
        path="/login"
        component={Login}
        layout={PublicLayout} />

      <AppRoute
        exact
        path="/registration"
        component={Registration}
        layout={PublicLayout} />

      <AppRoute
        exact
        path="/forgot-password"
        component={ForgotPassword}
        layout={PublicLayout} />


      <AppRoute
        exact
        path="/reset/:token"
        component={ResetPassword}
        layout={PublicLayout}
      />


      <PrivateRoute
        exact
        path="/dashboard"
        component={Dashboard}
        layout={PrivateLayout} />

      <PrivateRoute
        exact
        path="/dashboard/profile"
        component={Profile}
        layout={PrivateLayout}
      />

      {/* <PrivateRoute
        exact
        path="/dashboard/kyc"
        component={KYC}
        layout={PrivateLayout}
      /> */}

      <PrivateRoute
        exact
        path="/dashboard/portfolio"
        component={Portfolio}
        layout={PrivateLayout}
      />

      {/* <PrivateRoute
        exact
        path="/dashboard/kyc-process"
        component={KycProcess}
        layout={PrivateLayout}
      /> */}


      {/* <Route exact path="/discover" exact component={discover}></Route> */}
    </Switch>
  )
}

export default Routing
