import { Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'


const Step3 = ( props ) => {
    const { setActiveStep } = props


    const dispatch = useDispatch()






    return (
        <div className="py-md-6 p-5 mx-3 has-box-shadow">
            <div className="form-heading my-5">
                <h3 className="font-weight-bold text-dark">
                    Confirm your Email Address
                </h3>
                <h3 className="font-weight-bold text-dark">
                    for seam less experience
                </h3>
            </div>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    password_confirm: "",
                    // terms_acceptance: ""
                }}
                onSubmit={values => {
                    if ( !values.email ) {
                        toast.error( "Please Enter Email" )
                    } else if ( !values.password ) {
                        toast.error( "Please Enter Password" )
                    } else if ( !values.password_confirm ) {
                        toast.error( "Please Enter Password Confirm" )
                    } else if ( values.password !== values.password_confirm ) {
                        toast.error( "Your Password and Password Confirm are not same" )
                    }
                    // else if ( !values.terms_acceptance ) {
                    //     toast.error( "Please Enter Accecpt Terms" )
                    // }
                    else {
                        dispatch( { type: "SAVE_USER_TEMP_DATA", payload: values } )
                        setActiveStep( 4 )
                    }
                }}
            >

                {( {
                    handleSubmit,
                    handleChange,
                    values
                } ) => (
                    <form className="my-3" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control secondary-input"
                                id="email-id"
                                placeholder="Email Address"
                                name="email"
                                required
                                onChange={handleChange}
                                value={values.email}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control secondary-input"
                                id="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                placeholder="Create Password"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control secondary-input"
                                id="confirm-password"
                                placeholder="Confirm Password"
                                name="password_confirm"
                                onChange={handleChange}
                                value={values.password_confirm}
                            />
                        </div>

                        {/* <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={handleChange}
                                value={values.terms_acceptance}
                                id="terms_acceptance"
                                name="terms_acceptance"
                            />
                            <label
                                className="form-check-label font-weight-bold text-dark"
                                htmlFor="terms_acceptance">
                                Agree to terms and conditions
                            </label>
                        </div> */}
                        <button
                            id="signup-button"
                            className="btn btn-gradient-secondary w-100 my-5"
                            type="submit"
                        >
                            Sign Up
                        </button>
                    </form>
                )}

            </Formik>


        </div>
    )
}

export default Step3
