import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { ethers, Contract } from "ethers";
import BigNumber from "bignumber.js";
import factoryabi from "../../../abi/aqarfactory.json";
import vaultabi from "../../../abi/aqarvault.json";
import AQAR from "../../../abi/aqrbalance.json";
import Multicall from "../../../helper/multicall/multicall";
import { Link } from "react-router-dom";
import {
  factory,
  mapToken,
  usdtAddress,
  wethAddress,
  usdcAddress,
  // wbnbAddress,
  wbtcAddress,
  // wxtzAddress,
  aqrAddress,
} from "../../../constants/addresses.json";
import { useDispatch } from "react-redux";
import { getTotenizedProperty } from "../../../Redux/action/App";
import { useWeb3React } from "@web3-react/core";

const Portfolio = () => {
  const [propblockdata, setpropblockdata] = useState([]);
  const [allProperty, setAllProperty] = useState([]);
  const [aqrbalance, setaqrbalance] = useState(0);

  const dispatch = useDispatch();
  const { account, chainId } = useWeb3React();

  //getting each user balance
  const gettingPropDataFromBlockchain = async (tokens) => {
    const promises = tokens.map(async (token) => {
      const factorycalls = [
        {
          address: factory.address,
          name: "propIdTopropTokenId",
          params: [token.property.id],
        },
      ];
      const [a] = await Multicall(factoryabi.abi, factorycalls);

      const vaultaddrescall = [
        {
          address: factory.address,
          name: "NftToToken",
          params: [parseInt(a)],
        },
      ];

      const [b] = await Multicall(factoryabi.abi, vaultaddrescall);
      console.log(account, "port");
      if (account) {
        const vaultcalls = [
          {
            address: b.toString(),
            name: "claimableBalance",
            params: [account],
          },
          {
            address: b.toString(),
            name: "tokenPrice",
          },
          {
            address: b.toString(),
            name: "name",
          },
          {
            address: b.toString(),
            name: "symbol",
          },
        ];
        const [claimable, tokenPrice, name, symbol] = await Multicall(
          vaultabi.abi,
          vaultcalls
        );
        console.log(
          new BigNumber(parseInt(claimable))
            .dividedBy(new BigNumber(10).pow("18"))
            .toNumber()
            .toFixed(2)
        );
        var total = [
          new BigNumber(parseInt(claimable))
            .dividedBy(new BigNumber(10).pow("18"))
            .toNumber()
            .toFixed(3),
          new BigNumber(parseInt(tokenPrice))
            .dividedBy(new BigNumber(10).pow("18"))
            .toNumber()
            .toFixed(2),
          name,
          symbol,
        ];
        return total;
      }
    });

    const resp = await Promise.all(promises);
    console.log(resp, "response");
    setpropblockdata(resp);
  };

  const getuserbalance = async () => {
    if (account) {
      const mapcalls = [
        {
          address: mapToken.address,
          name: "claimableBalanceSeed",
          params: [account],
        },
        {
          address: mapToken.address,
          name: "claimableBalancePrivate",
          params: [account],
        },
      ];
      const [seed, priv] = await Multicall(AQAR.abi, mapcalls);
    //   console.log(seed, priv, "investment");
      var amount = new BigNumber(parseInt(seed))
          .dividedBy(new BigNumber(10).pow('18'))
          .toNumber()
          .toFixed(2);
          var amount2 = new BigNumber(parseInt(priv))
          .dividedBy(new BigNumber(10).pow('18'))
          .toNumber()
          .toFixed(2);
       //   console.log(seed,priv,amount,amount2,"investment")
          setaqrbalance(Number(amount)+Number(amount2))
    }

    //       console.log(account,chainId,"account")
    //       if(account && chainId){
    //       const userinfo = await contract.usermapseed(account);
    //       const userinfo2 = await contract.usermapprivate(account);
    // console.log(userinfo,userinfo2,"ajaaja")
    //       var amount = new BigNumber(parseInt(userinfo.amount))
    //       .dividedBy(new BigNumber(10).pow('18'))
    //       .toNumber()
    //       .toFixed(2);
    //       var amount2 = new BigNumber(parseInt(userinfo2.amount))
    //       .dividedBy(new BigNumber(10).pow('18'))
    //       .toNumber()
    //       .toFixed(2);
    //       setaqrbalance(Number(amount)+Number(amount2))
    //       }
  };

  const loadProperty = async () => {
    const body = {
      limit: "1000",
      filter: {
        propertyTitle: "",
      },
    };
    await dispatch(getTotenizedProperty(body))
      .then(
        (response) => {
          setAllProperty(response.data);

          gettingPropDataFromBlockchain(response.data);
        },
        (error) => {
          console.log("response", error);
        }
      )
      .catch((error) => {
        console.log("response", error);
      });
  };

  useEffect(() => {
    loadProperty();
    getuserbalance();
  }, [account]);

  return (
    <div
      class="tab-pane fade active show"
      id="user-dashboard-nav-tab-my-portfolio"
      role="tabpanel"
      aria-labelledby="user-dashboard-nav-tab-my-portfolio-tab"
    >
      <div class="col-lg-4 col-12">
        <h3 class="font-weight-bold mb-3 mb-lg-4">
          My AQR Balance: {aqrbalance} AQR
        </h3>
      </div>
      <div class="text-center text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius">
        <div class="row d-lg-flex align-items-center">
          <div class="col-lg-4 col-12">
            <h3 class="font-weight-bold mb-3 mb-lg-4">My Portfolio</h3>
          </div>

          {/* <div class="col-lg-8 col-12">
            <div id="search-filter_options" class="mb-3 mb-lg-4">
              <div class="d-flex">
                <div id="search-sort_by" class="search-filter_option">
                  <span class="mr-3">Sort By</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    class="icon-search-filter_options"
                  >
                    <title>Sort By</title>
                    <g
                      id="icon-search-filter_options-sort_by-1"
                      data-name="Layer 1"
                    >
                      <g
                        id="icon-search-filter_options-sort_by-2"
                        data-name="Layer 2"
                      >
                        <g
                          id="icon-search-filter_options-sort_by-3"
                          data-name="Layer 3"
                        >
                          <path d="M14.18,21.11,9.23,26.06V1.15a1.16,1.16,0,0,0-2.31,0V26.06L2,21.11A1.15,1.15,0,1,0,.34,22.74l6.92,6.92a1.14,1.14,0,0,0,.82.34,1.12,1.12,0,0,0,.81-.34l6.93-6.92a1.15,1.15,0,0,0,0-1.63,1.16,1.16,0,0,0-1.64,0Z"></path>
                          <path d="M29.66,7.26,22.74.34a1.15,1.15,0,0,0-1.63,0L14.18,7.26a1.15,1.15,0,0,0,0,1.63,1.16,1.16,0,0,0,1.64,0l4.95-5V28.85a1.16,1.16,0,0,0,2.31,0V3.94l5,5a1.14,1.14,0,0,0,.82.34,1.12,1.12,0,0,0,.81-.34,1.15,1.15,0,0,0,0-1.63Z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div id="search-filters" class="search-filter_option">
                  <span class="mr-3">Filter</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32.31 30"
                    class="icon-search-filter_options"
                  >
                    <title>Filter</title>
                    <g
                      id="icon-search-filter_options-filter-1"
                      data-name="Layer 1"
                    >
                      <g
                        id="icon-search-filter_options-filter-2"
                        data-name="Layer 2"
                      >
                        <g
                          id="icon-search-filter_options-filter-3"
                          data-name="Layer 3"
                        >
                          <path d="M28.85,11.54H3.46a1.16,1.16,0,0,1,0-2.31H28.85a1.16,1.16,0,0,1,0,2.31Z"></path>
                          <path d="M24.23,20.77H8.08a1.16,1.16,0,1,1,0-2.31H24.23a1.16,1.16,0,0,1,0,2.31Z"></path>
                          <path d="M19.62,30H12.69a1.16,1.16,0,0,1,0-2.31h6.93a1.16,1.16,0,0,1,0,2.31Z"></path>
                          <path d="M31.15,2.31h-30A1.16,1.16,0,0,1,1.15,0h30a1.16,1.16,0,1,1,0,2.31Z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div class="dashboard-table-container table-responsive">
          <table class="dashboard-table">
            <thead>
              <tr>
                <th>Token Name</th>
                <th>Token Code</th>
                <th>Token Price</th>
                <th>Tokens</th>
                <th>Investment Value</th>
              </tr>
            </thead>
            <tbody>
              {allProperty.length !== 0
                ? allProperty.map((key, index) =>
                    propblockdata.length !== 0 &&
                    propblockdata[index] &&
                    propblockdata[index][0] > 0 ? (
                      <tr>
                        <td>
                          {propblockdata.length !== 0 &&
                            propblockdata[index] &&
                            propblockdata[index][2]}
                        </td>
                        <td>
                          {propblockdata.length !== 0 &&
                            propblockdata[index] &&
                            propblockdata[index][3]}
                        </td>
                        <td>
                          {propblockdata.length !== 0 &&
                            propblockdata[index] &&
                            propblockdata[index][1]}
                        </td>
                        <td>
                          {propblockdata.length !== 0 &&
                            propblockdata[index] &&
                            propblockdata[index][0]}
                        </td>
                        <td>
                          {propblockdata.length !== 0 &&
                            propblockdata[index] &&
                            (
                              propblockdata[index][1] * propblockdata[index][0]
                            ).toFixed(2)}
                        </td>
                        {/* <td>
                    <a href="" class="btn btn-blue btn-sm btn-rounded-sm mb-0">
                      View
                    </a>
                    <a href="" class="btn btn-blue btn-sm btn-rounded-sm mb-0">
                      Sell
                    </a>
                    <a href="" class="btn btn-blue btn-sm btn-rounded-sm mb-0">
                      Buy
                    </a>
                  </td> */}
                      </tr>
                    ) : null
                  )
                : null}
            </tbody>
          </table>
        </div>
        <div class="dashboard-table-meta mt-4">
          <div class="row d-lg-flex align-items-center">
            <div class="col-lg-6">
              {/* <h4 class="font-weight-bold mb-3 mb-lg-0">
                Show
                <select id="dashboard-table-no_of_results" class="mx-1">
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                Entries
              </h4> */}
            </div>
            {/* <div class="col-lg-6 text-center text-lg-right">
              <nav class="dashboard-table-pagination">
                <ul class="pagination mb-0">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      Previous
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;