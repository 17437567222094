import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import { login } from "../../Redux/action/Auth";
import IsLoadingHOC from '../IsLoadingHOC'


const Login = ( props ) => {

  const { setLoading } = props;
  const { login, history } = props

  return (
    <div id="user-registration-section_1-signup-step6" className="container-lg">
      <div className="row no-gutters">
        <div className="col-xl-1 col-md-12">
          <div className="user-registration-card-bg">
            <img alt="" className="p-4" src="/images/dots-variant-1.svg" />
          </div>
        </div>
        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
          <div className="py-md-7 p-5 mx-3 has-box-shadow">
            <div className="form-heading my-5">
              <h3 className="font-weight-bold text-dark">
                Login or Signup to take
              </h3>
              <h3 className="font-weight-bold text-dark">
                your Property Listing to a whole new level
              </h3>
            </div>
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={async ( values ) => {
                setLoading(true)
                await login( values )
                  .then(
                    response => {
                      toast.success( response.message );
                      // history.push( "/discover" )
                      setLoading(false)
                      history.push( "/dashboard/profile" )
                    },
                    error => {
                      setLoading(false)
                      toast.error( error.response.data.message )
                    }
                  )
                  .catch( error => {
                    setLoading(false)
                    console.log( error );
                  } )
              }}
            >

              {( {
                values,
                handleChange,
                handleSubmit,
              } ) => (

                <form className="my-4" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      className="form-control secondary-input"
                      id="email-address"
                      placeholder="Mobile Number/Email ID"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      className="form-control secondary-input"
                      id="password2"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div>
                    <span className="form-text text-muted text-center font-weight-bold text-dark float-right">
                      <Link to={'/forgot-password'}>Forgot Password?</Link>
                    </span>
                  </div>
                  <button
                    type="submit"
                    id="signup-button"
                    className="btn btn-gradient-secondary w-100 my-6"
                  >
                    Login
                  </button>
                </form>

              )}

            </Formik>

            <small className="form-text text-muted text-center">
              Don't have an account?{' '}
              <span
                className="font-weight-bold text-muted">
                <Link to={'/registration'}>Click here to register</Link>
              </span>
            </small>
          </div>
        </div>
        <div className="col-lg-6 col-12 text-center text-lg-left">
          <div className="registration-artwork-top bg-dots-variant-1 bg-position-center-right-offset bg-repeat-no-repeat p-5">
            <h2>Take a Break from Conventional</h2>
            <h2> listing methods and focus</h2>
            <h2>
              on <span className="font-weight-bold">what matters the most</span>
            </h2>
          </div>
          <div className="registration-artwork-bottom">
            <img
              alt=""
              className="img-fluid w-90"
              src="/images/User-Registration-Artwork.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect( null, { login } )( IsLoadingHOC(Login) );


