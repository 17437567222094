import { Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { forgotPassword } from '../../Redux/action/Auth'
import IsLoadingHOC from '../IsLoadingHOC'

const ForgotPassword = (props) => {
    const dispatch = useDispatch()
    const { setLoading } = props

    


    return (
        <div id="user-registration-section_1-signup-step6" className="container-lg">
            <div className="row no-gutters">
                <div className="col-xl-1 col-md-12">
                    <div className="user-registration-card-bg">
                        <img alt="" className="p-4" src="/images/dots-variant-1.svg" />
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                    <div className="py-md-6 p-5 mx-3 has-box-shadow">
                        <div className="form-heading my-5">
                            <h3 className="font-weight-bold text-dark">
                            Confirm your Email Address
                            </h3>
                            <h3 className="font-weight-bold text-dark">
                            for seam less experience
                            </h3>
                        </div>
                        <Formik
                            initialValues={{
                                email:""
                            }}
                            onSubmit={async ( values, action ) => {
                                setLoading(true)
                                await dispatch( forgotPassword( values ) )
                                    .then(
                                        response => {
                                            toast.success(response.message)
                                            setLoading(false)
                                        },
                                        error => {
                                            toast.error(error.data.message)
                                            setLoading(false)
                                        }
                                    )
                                    .catch()
                            }}
                        >
                            {( {
                                handleChange,
                                handleSubmit,
                                values } ) => (
                                <form className="my-3" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control secondary-input"
                                            id="email-id"
                                            placeholder="Email Address"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <button
                                        id="signup-button"
                                        className="btn btn-gradient-secondary w-100 my-5">
                                        Reset Password
                                    </button>
                                </form>
                            )}

                        </Formik>
                        
                       
                    </div>
                </div>
                <div className="col-lg-6 col-12 text-center text-lg-left">
                    <div className="registration-artwork-top bg-dots-variant-1 bg-position-center-right-offset bg-repeat-no-repeat p-5">
                        <h2>Take a break from</h2>
                        <h2>financial work, and focus</h2>
                        <h2>
                        on{' '}
                        <span className="font-weight-bold">
                            what matters the most
                        </span>
                        </h2>
                    </div>
                    <div className="registration-artwork-bottom">
                        <img
                        alt="User Registration Artwork"
                        className="img-fluid w-90"
                        src="/images/User-Registration-Artwork.svg"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(ForgotPassword)
