import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getDocumentsVerify, getVerifications, uploadFiles } from '../../../Redux/action/Kyc'
import IsLoadingHOC from '../../IsLoadingHOC'
import Select from 'react-select';
import { updateUserProfile } from '../../../Redux/action/Auth'
const AMLCheck = ( props ) => {

    const { setLoading, setActiveStep } = props
    const { tempData } = useSelector( state => state.auth )
    const dispatch = useDispatch()
    const history = useHistory()
    const [docFront, setDocFront] = useState( "" )
    const [disabled, setDisabled] = useState( false )
    const [values, setValues] = useState( {
        applicant_id: tempData.applicant_id,
        type: "FINANCIAL_DOCUMENT",
        income_sources: [],
        annual_income: "",
        transaction_amount: "",
        transaction_currency: "AED",
        transaction_datetime: "",
        transaction_purpose: "",
        origin_funds: "",
        front_side_id: "",
    } )


    const submitHanlder = async () => {
        const {
            income_sources,
            annual_income,
            transaction_amount,
            transaction_datetime,
            transaction_purpose,
            origin_funds } = values

        if ( !income_sources ) {
            toast.error( "Please Select Type Of Income" )
            return
        } else if ( !annual_income ) {
            toast.error( "Please Select Annnual Icons Group" )
            return
        } else if ( !transaction_amount ) {
            toast.error( "Please Enter Transaction amount" )
            return
        } else if ( !transaction_purpose ) {
            toast.error( "Please Enter Purpose of the transaction" )
            return
        } else if ( !origin_funds ) {
            toast.error( "Please Enter Detailed explanation of origin of the funds" )
            return
        } else {
            setLoading( true )
            await dispatch( getDocumentsVerify( values ) )
                .then(
                    async response => {
                        toast.success( "Submitted Successfully!!" )
                        await dispatch( getVerifications( {
                            applicant_id: tempData.applicant_id,
                            types: ["PROFILE", "DOCUMENT", "AML"],
                            callback_url: "https://token-api.aqarchain.io/admin/kyc/"
                        } ) )
                            .then(
                                async response => {
                                    await dispatch( updateUserProfile( {
                                        kyc: { verification_id: response.verification_id }
                                    } ) )
                                        .then(
                                            response => {
                                                setLoading( false )
                                                history.push("/dashboard/kyc")
                                            },
                                            error => {
                                                setLoading( false )
                                                toast.error( "Something went wrong" )
                                                setActiveStep( 0 )
                                            }
                                        )
                                        .catch( error => console.log( error ) )
                                },
                                error => {
                                    setLoading( false )
                                    error.response.data.errors.map( ( error ) => {
                                        toast.error( error.message )
                                    } )
                                }
                            )
                            .catch( error => console.log( error ) )
                    },
                    error => {
                        setLoading( false )
                        error.response.data.errors.map( ( error ) => {
                            toast.error( error.message )
                        } )
                    }
                )
                .catch( error => console.log( error ) )
        }
    }

    const trimFileName = (string, l = 5) => {
        if ( string.length > 25 ) {
            return (
                String(string).slice(0, 5) +
                String(string).slice(2, 2 + l) +
                "..." +
                String(string).slice(string.length - l, string.length)
            )
        } else {
            return string
        }
    }

    const incomeSourcesList = [
        { value: "Dividents", label: "Dividents" },
        { value: "Salary", label: "Salary" },
        { value: "Loan", label: "Loan" },
        { value: "Inheritance", label: "Inheritance" },
    ]


    return (
        <div className="kyc-process-inner-card py-md-5 px-lg-5 p-4 mx-md-3 mx-0 has-box-shadow">
            <div className="row no-gutters text-center p-3">
                <div className="col-12">
                    <h2 className="font-weight-bold">AML Check</h2>
                </div>
            </div>
            <div className="col-lg-12 col-12 my-3">
                <label htmlFor="type_of_income">Type Of Income</label>
                <Select
                    isMulti
                    name="type_of_income"
                    options={incomeSourcesList}
                    onChange={e => {
                        const incomeList = []
                        e.map( ( item ) => {
                            incomeList.push( item.value )
                        } )
                        setValues( { ...values, income_sources: incomeList } )
                    }}
                    className="basic-multi-select"
                />
            </div>
            <div className="col-lg-12 col-12 my-3">
                <div className="form-group">
                    <label htmlFor="annual_income">Annnual Icons Group *</label>
                    <select
                        className="form-control secondary-select annual_income"
                        name="annual_income"
                        id="annual_income"
                        value={values.annual_income}
                        onChange={e => setValues( { ...values, annual_income: e.target.value } )}
                        placeholder="Annual income amount">
                        <option value="" disabled="">Annual income amount *</option>
                        <option value="ANNUAL_INCOME_GROUP_1">Below 50000 AED</option>
                        <option value="ANNUAL_INCOME_GROUP_2">50000 - 100000 AED</option>
                        <option value="ANNUAL_INCOME_GROUP_3">100000 - 300000 AED</option>
                        <option value="ANNUAL_INCOME_GROUP_4">above 300000 AED</option>
                    </select>
                </div>
            </div>
            <div className="col-lg-12 col-12 my-3">
                <div className="form-group">
                    <label htmlFor="transaction_amount">Transaction amount *</label>
                    <input
                        type="number"
                        className="form-control secondary-input"
                        id="transaction_amount"
                        value={values.transaction_amount}
                        onChange={e => setValues( { ...values, transaction_amount: Number( e.target.value ) } )}
                        placeholder="Transaction amount" />
                </div>
            </div>
            <div className="col-lg-12 col-12 my-3">
                <div className="form-group">
                    <label htmlFor="purpose_of_transactoin">Purpose of the transaction *</label>
                    <input
                        type="text"
                        className="form-control secondary-input"
                        id="purpose_of_transactoin"
                        value={values.transaction_purpose}
                        onChange={e => setValues( { ...values, transaction_purpose: e.target.value } )}
                        placeholder="Purpose of the transaction" />
                </div>
            </div>
            <div className="col-lg-12 col-12 my-3">
                <div className="form-group">
                    <label htmlFor="origin_funds">Detailed explanation of origin of the funds *</label>
                    <input
                        type="text"
                        className="form-control secondary-input"
                        id="origin_funds"
                        value={values.origin_funds}
                        onChange={e => setValues( { ...values, origin_funds: e.target.value } )}
                        placeholder="Purpose of the transaction" />
                </div>
            </div>
            <div className="col-12 d-flex mt-md-0 mt-3">
                <label className="dashed-border p-4" htmlFor="docFront">
                    <div className="image-upload">
                        <img className="mx-auto cursor" src={require( "../../../assets/images/Upload.svg" ).default} style={{ width: "30px" }} />
                        <input
                            type="file"
                            className="custom-file-input d-none"
                            accept="image/*"
                            onChange={async e => {
                                setLoading( true )
                                setDocFront( e.target.files[0] )
                                let formData = new FormData()
                                formData.append( "file", e.target.files[0] )
                                await dispatch( uploadFiles( formData ) )
                                    .then(
                                        response => {
                                            setLoading( false )
                                            setDisabled( false )
                                            setValues( { ...values, front_side_id: response.data.file_id } )
                                        },
                                        error => {
                                            setLoading( false )
                                            console.log( "responseError", error.response.data )
                                            setDisabled( true )
                                            if ( error.response.data.errors ) {
                                                toast.error( error.response.data.errors[0].message )
                                            }
                                        }
                                    )
                                    .catch()
                            }}
                            id="docFront" />
                        <p className="mt-3">Upload proof of source of wells</p>
                        <small>{!docFront
                            ? "We accept BMP, GIF, HEIC, JPEG, PNG and WEBP, up to 50 MB"
                            : trimFileName( docFront.name )}</small>
                    </div>
                </label>
            </div>
            <div className="row">
                <div className="col-lg-3 offset-lg-9 col-md-6 offset-md-6 col-12">
                    <button
                        className="btn btn-gradient-secondary w-100"
                        disabled={disabled}
                        onClick={submitHanlder} >Next</button>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC( AMLCheck )
