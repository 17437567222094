import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Login from '../login/login'
import ResendVerifyLink from './resendLink'
import IsLoadingHOC from '../IsLoadingHOC'
import { toast } from 'react-toastify'
import { verifyEmailToken } from '../../Redux/action/Auth'

const EmailVerify = (props) => {
    const history = useHistory();
    const { setLoading } = props;
    const [verifying, setVerifying] = useState(true)
    const params = useParams()

    useEffect( () => {
        setLoading(true)
        verifyEmailToken({token: params.token }).then((res)=>{
            setVerifying(true);
            setLoading(false);
            toast.success("Email verified");
            history.push('/login')
        },(error)=>{
            setLoading(false)
            setVerifying(false)
            if ( Array.isArray(error.response.data.message) ) {
                error.response.data.message.map( item => toast.error(item) )
            } else {
                toast.error(error.response.data.message)
            }
        })
      },[params.token])

    return verifying ? <Login/> : <ResendVerifyLink/>;
}

export default IsLoadingHOC(EmailVerify);
